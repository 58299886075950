<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-file-tree"
      inline
      class="px-5 py-4 ml-0 pb-10"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Cálculo de Perdas Técnicas #{{ calculo.id }}
        </div>
      </template>
      <v-row>
        <v-col
          cols="12"
          class="d-flex flex-row-reverse"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="mt-n10"
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  icon
                  color="black"
                  @click="getLogsCalculoPerdas"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="22"> mdi-refresh </v-icon>
                </v-btn>
              </div>
            </template>
            Atualizar
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider class="my-4" />
      <span>
        <v-row>
          <v-col cols="4">
            <label class="d-block mb-1"> Título: </label>
            <v-label>{{ calculo.nome }}</v-label>
          </v-col>
          <v-col cols="4">
            <label class="d-block mb-1"> Mês dos dados: </label>
            <v-label>
              {{ calculo.data_registro | formatToMonth }} v{{ calculo.versao }}
            </v-label>
          </v-col>
          <v-col cols="4">
            <label class="d-block mb-1"> N° de CTMTs: </label>
            <v-label>
              {{ calculo.numero_ctmts_selecionados }}
            </v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <label class="d-block mb-1"> Servidor: </label>
            <v-label>
              <div :title="`Host: ${calculo.host} Port: ${calculo.port}`">
                {{ calculo.servidor }}
              </div>
            </v-label>
          </v-col>
          <v-col cols="4">
            <label class="d-block mb-1"> Database: </label>
            <v-label>
              {{ calculo.database }}
            </v-label>
          </v-col>
          <v-col cols="4">
            <label class="d-block mb-1"> Criado por: </label>
            <v-label>
              {{ calculo.usuario }} em {{ calculo.created | parseToDateTimeBR }}
            </v-label>
          </v-col>
        </v-row>
      </span>
      <v-divider class="mt-2 mb-2" />
      <v-row>
        <v-col cols="12">
          <status-card
            :calculos="calculo.calculos_perdas"
            :status="calculo.status"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-2 mb-2" />
      <v-row>
        <v-col cols="3">
          <v-card tile>
            <v-subheader>
              <strong>Aguardando</strong>
            </v-subheader>
            <v-divider />
            <v-card-text>
              <div
                class="d-flex justify-center align-center"
                style="height: 120px"
              >
                <h1 class="texto-aguardando">
                  {{ totalAguardando | parseNumberToIntegerBR }}
                </h1>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card tile>
            <v-subheader>
              <strong>Executando</strong>
            </v-subheader>
            <v-divider />
            <v-card-text>
              <div
                class="d-flex justify-center align-center"
                style="height: 120px"
              >
                <h1 class="texto-executando">
                  {{ totalExecutando | parseNumberToIntegerBR }}
                </h1>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card tile>
            <v-subheader>
              <strong>
                Falha :: máximo de
                {{ calculo.maximo_tentativas }}
                <span v-if="calculo.maximo_tentativas > 1"> tentativas </span>
                <span v-else> tentativa </span>
              </strong>
            </v-subheader>
            <v-divider />
            <v-card-text>
              <div
                class="d-flex justify-center align-center"
                style="height: 120px"
              >
                <h1
                  class="texto-falha clickable-item"
                  @click="openDialogCtmtsComStatusFalha"
                >
                  {{ totalFalha | parseNumberToIntegerBR }}
                </h1>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card tile>
            <v-subheader>
              <strong>Sucesso</strong>
            </v-subheader>
            <v-divider />
            <v-card-text>
              <div
                class="d-flex justify-center align-center"
                style="height: 120px"
              >
                <h1
                  class="texto-sucesso clickable-item"
                  @click="openDialogCtmtsComStatusSucesso"
                >
                  {{ totalSucesso | parseNumberToIntegerBR }}
                </h1>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="filtrarPorStatus"
            label="Filtrar por status"
            :items="[
              { value: 'TODOS', label: 'Todos' },
              { value: 'AGUARDANDO', label: 'Aguardando' },
              { value: 'EXECUTANDO', label: 'Executando' },
              { value: 'FALHA', label: 'Falha' },
              { value: 'SUCESSO', label: 'Sucesso' }
            ]"
            item-value="value"
            item-text="label"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto break-search"
            label="Procurar"
            hide-details
            single-line
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="[
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'CTMT',
            value: 'ctmt'
          },
          {
            text: 'Duração do cálculo',
            value: 'job_duration'
          },
          {
            text: 'Últimas linhas do log',
            value: 'job_logs_ultimas_x_linhas'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            sortable: false,
            text: 'Ações',
            value: 'actions',
            class: 'pl-4'
          }
        ]"
        :items="calculosPerdas"
        :search.sync="search"
        :sort-by="['job_stopped_at']"
        :sort-desc="['job_stopped_at']"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhum cálculo encontrado"
      >
        <template v-slot:[`item.id`]="{ item }">
          <span :title="`Job Id: ${item.job_id}\nJob Name: ${item.job_name}`">
            {{ item.id }}
          </span>
        </template>
        <template v-slot:[`item.job_duration`]="{ item }">
          <div>
            {{ parseInt(item.job_duration / 1000) | formatDuration }}
          </div>
          <div class="texto-pequeno">
            <span v-if="item.job_started_at">
              Início: {{ item.job_started_at | parseToDateTimeBR }}
            </span>
            <span v-if="item.job_stopped_at">
              Fim: {{ item.job_stopped_at | parseToDateTimeBR }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.job_logs_ultimas_x_linhas`]="{ item }">
          <span :title="item.job_logs_ultimas_x_linhas">
            ... {{ item.job_logs_ultima_linha.slice(-60) }}
          </span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <general-status
            :status="item.status"
            :title="`Job Status: ${item.job_status} ${
              item.job_is_cancelled || item.job_is_terminated
                ? '(CANCELADO)'
                : ''
            }\nJob Status Reason: ${item.job_status_reason}`"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :class="!item.job_log_stream_name ? 'cursor-block' : ''"
            class="px-1"
            min-width="0"
            fab
            icon
            x-small
            @click="() => baixarLogsCalculo(item)"
            :title="`Baixar logs do cálculo do CTMT: ${item.ctmt}`"
            :loading="loadingLogsCalculo[item.id]"
            :disabled="!item.job_log_stream_name"
          >
            <v-icon small> mdi-download </v-icon>
          </v-btn>
          <v-btn
            v-if="!['SUCESSO', 'FALHA'].includes(item.status)"
            :title="`Cancelar o cálculo para o CTMT: ${item.ctmt}`"
            class="px-1"
            color="red"
            min-width="0"
            fab
            icon
            x-small
            @click="() => openDialogCancelarCalculo(item)"
          >
            <v-icon small> mdi-pause-octagon-outline </v-icon>
          </v-btn>
          <v-btn
            v-if="['SUCESSO', 'FALHA'].includes(item.status)"
            :title="`Reprocessar o cálculo para o CTMT: ${item.ctmt}`"
            class="px-1"
            min-width="0"
            fab
            icon
            x-small
            @click="() => reprocessarCtmts([item.ctmt])"
          >
            <v-icon small> mdi-play-box </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="getLogsCalculoPerdas"
              >
                mdi-refresh
              </v-icon>
            </template>
            <span> Atualizar </span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider class="mt-8 mb-8" />
      <v-row
        v-if="
          !loading &&
          this.calculo.calculos_perdas.length &&
          ['AGUARDANDO', 'EXECUTANDO'].includes(calculo.status)
        "
      >
        <v-col cols="12">
          <v-btn
            color="red darken-1"
            min-width="100"
            class="mt-n5"
            style="float: right"
            @click="dialogCancelarTodosCalculos = true"
          >
            Cancelar Todos
          </v-btn>
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog
      v-model="dialogCancelarCalculo"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>
            Cancelar o cálculo para o CTMT: {{ dialogCancelarCalculoData.ctmt }}
          </h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          <v-textarea
            v-model="motivoCancelamento"
            label="Qual o motivo do cancelamento?"
            rows="5"
            no-resize
          />
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="red darken-1"
            :disabled="!motivoCancelamento"
            :loading="loadingCancelarCalculo"
            @click="cancelarCalculo"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCancelarTodosCalculos"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>Cancelar todos os cálculos?</h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          <v-textarea
            v-model="motivoCancelamento"
            label="Qual o motivo do cancelamento?"
            rows="5"
            no-resize
          />
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="red darken-1"
            :disabled="!motivoCancelamento"
            :loading="loadingCancelarTodosCalculos"
            @click="cancelarTodosCalculos"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCtmtsComStatusFalha"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>CTMTs com status falha</h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          <v-text-field
            v-model="searchCtmtsComStatusFalha"
            append-icon="mdi-magnify"
            class="ml-auto break-search"
            label="Procurar CTMT"
            hide-details
            single-line
          />
          <v-textarea
            class="ta-padding-c"
            rows="10"
            no-resize
            background-color="#FFEBEE"
            :readonly="true"
            :value="ctmtsComStatusFalhaParaReprocessar.join('\n')"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="blue darken-1"
            :disabled="!ctmtsComStatusFalhaParaReprocessar.length"
            :loading="loadingReprocessarCtmts"
            @click="() => reprocessarCtmts(ctmtsComStatusFalhaParaReprocessar)"
          >
            Reprocessar
          </v-btn>
          <v-btn
            color="green darken-1"
            @click="dialogCtmtsComStatusFalha = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCtmtsComStatusSucesso"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>CTMTs com status sucesso</h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          <v-text-field
            v-model="searchCtmtsComStatusSucesso"
            append-icon="mdi-magnify"
            class="ml-auto break-search"
            label="Procurar CTMT"
            hide-details
            single-line
          />
          <v-textarea
            class="ta-padding-c"
            rows="10"
            no-resize
            background-color="#E0F2F1"
            :readonly="true"
            :value="ctmtsComStatusSucessoParaReprocessar.join('\n')"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="blue darken-1"
            :disabled="!ctmtsComStatusSucessoParaReprocessar.length"
            :loading="loadingReprocessarCtmts"
            @click="
              () => reprocessarCtmts(ctmtsComStatusSucessoParaReprocessar)
            "
          >
            Reprocessar
          </v-btn>
          <v-btn
            color="green darken-1"
            @click="dialogCtmtsComStatusSucesso = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ProgGeoPerdasAneelService from '@/services/ProgGeoPerdasAneelService';
import StatusCard from '@/components/perdas-tecnicas/prog-geo-perdas-aneel/StatusCard.vue';
import GeneralStatus from '@/components/general/GeneralStatus.vue';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  mixins: [refreshDataMixins],
  components: {
    StatusCard,
    GeneralStatus
  },
  data: () => ({
    calculo: {
      id: null,
      calculos_perdas: []
    },
    search: null,
    searchCtmtsComStatusFalha: null,
    filtrarPorStatus: 'TODOS',
    loading: false,
    loadingCancelarCalculo: false,
    loadingCancelarTodosCalculos: false,
    loadingReprocessarCtmts: false,
    loadingLogsCalculo: {},
    dialogCancelarCalculo: false,
    dialogCancelarCalculoData: {},
    dialogCtmtsComStatusFalha: false,
    dialogCtmtsComStatusFalhaData: [],
    dialogCtmtsComStatusSucesso: false,
    dialogCtmtsComStatusSucessoData: [],
    dialogCancelarTodosCalculos: false,
    motivoCancelamento: null
  }),
  async created() {
    await this.refreshData(this.getLogsCalculoPerdas);
  },
  computed: {
    totalAguardando() {
      return this.calculo.calculos_perdas.filter((calculo) =>
        ['AGUARDANDO'].includes(calculo.status)
      ).length;
    },
    totalExecutando() {
      return this.calculo.calculos_perdas.filter((calculo) =>
        ['EXECUTANDO'].includes(calculo.status)
      ).length;
    },
    totalFalha() {
      return this.calculo.calculos_perdas.filter((calculo) =>
        ['FALHA'].includes(calculo.status)
      ).length;
    },
    totalSucesso() {
      return this.calculo.calculos_perdas.filter((calculo) =>
        ['SUCESSO'].includes(calculo.status)
      ).length;
    },
    ctmtsComStatusFalha() {
      return this.calculo.calculos_perdas
        .filter((calculo) => calculo.status.toUpperCase() === 'FALHA')
        .map((calculo) => calculo.ctmt);
    },
    ctmtsComStatusSucesso() {
      return this.calculo.calculos_perdas
        .filter((calculo) => calculo.status.toUpperCase() === 'SUCESSO')
        .map((calculo) => calculo.ctmt);
    },
    calculosPerdas() {
      if (this.filtrarPorStatus !== 'TODOS') {
        return this.calculo.calculos_perdas.filter(
          (calculo) =>
            calculo.status.toUpperCase() === this.filtrarPorStatus.toUpperCase()
        );
      }
      return this.calculo.calculos_perdas;
    },
    ctmtsComStatusFalhaParaReprocessar() {
      if (this.searchCtmtsComStatusFalha) {
        return this.dialogCtmtsComStatusFalhaData.filter((ctmt) => {
          const query = this.searchCtmtsComStatusFalha.toLowerCase();
          return ctmt.toLowerCase().startsWith(query);
        });
      }
      return this.dialogCtmtsComStatusFalhaData;
    },
    ctmtsComStatusSucessoParaReprocessar() {
      if (this.searchCtmtsComStatusSucesso) {
        return this.dialogCtmtsComStatusSucessoData.filter((ctmt) => {
          const query = this.searchCtmtsComStatusSucesso.toLowerCase();
          return ctmt.toLowerCase().startsWith(query);
        });
      }
      return this.dialogCtmtsComStatusSucessoData;
    }
  },
  methods: {
    getLogsCalculoPerdas() {
      if (!this.$route.params.id) return;
      this.loading = true;
      ProgGeoPerdasAneelService.getLogsCalculoPerdas(this.$route.params.id)
        .then((response) => (this.calculo = response.data.data))
        .catch((err) => console.error(err))
        .finally(() => (this.loading = false));
    },
    openDialogCancelarCalculo(calculo) {
      this.dialogCancelarCalculoData = calculo;
      this.dialogCancelarCalculo = true;
    },
    openDialogCtmtsComStatusFalha() {
      this.dialogCtmtsComStatusFalhaData = this.ctmtsComStatusFalha;
      this.dialogCtmtsComStatusFalha = true;
    },
    openDialogCtmtsComStatusSucesso() {
      this.dialogCtmtsComStatusSucessoData = this.ctmtsComStatusSucesso;
      this.dialogCtmtsComStatusSucesso = true;
    },
    reprocessarCtmts(ctmts) {
      this.loadingReprocessarCtmts = true;
      const loteId = this.$route.params.id;
      ProgGeoPerdasAneelService.reprocessarCtmts(loteId, ctmts)
        .then(() => {
          this.$toast.success('CTMTs agendados para processamento.', '', {
            position: 'topRight'
          });
          this.getLogsCalculoPerdas();
          this.dialogCtmtsComStatusFalha = false;
          this.dialogCtmtsComStatusSucesso = false;
        })
        .catch((err) => {
          this.$toast.error(
            'Erro ao tentar agendar o processamento dos CTMTs.',
            '',
            {
              position: 'topRight'
            }
          );
          console.error(err);
        })
        .finally(() => (this.loadingReprocessarCtmts = false));
    },
    cancelarCalculo() {
      this.loadingCancelarCalculo = true;
      const calculo = this.dialogCancelarCalculoData;
      ProgGeoPerdasAneelService.cancelarCalculo(
        calculo.id,
        this.motivoCancelamento
      )
        .then(() => {
          this.$toast.success(
            'Solicitação de cancelamento de cálculo enviada com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.dialogCancelarCalculoData = {};
          this.dialogCancelarCalculo = false;
          this.getLogsCalculoPerdas();
        })
        .catch((err) => {
          this.$toast.error('Erro ao tentar cancelar o cálculo do CTMT.', '', {
            position: 'topRight'
          });
          console.error(err);
        })
        .finally(() => (this.loadingCancelarCalculo = false));
    },
    cancelarTodosCalculos() {
      const loteId = this.$route.params.id;
      if (!loteId) return;
      this.loadingCancelarTodosCalculos = true;
      ProgGeoPerdasAneelService.cancelarTodosCalculos(
        loteId,
        this.motivoCancelamento
      )
        .then(() => {
          this.$toast.success(
            'Solicitação de cancelamento de todos os cálculos enviada com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.dialogCancelarTodosCalculos = false;
          this.getLogsCalculoPerdas();
        })
        .catch((err) => {
          this.$toast.error(
            'Erro ao tentar cancelar os cálculos de todos os CTMTs.',
            '',
            {
              position: 'topRight'
            }
          );
          console.error(err);
        })
        .finally(() => (this.loadingCancelarTodosCalculos = false));
    },
    baixarLogsCalculo(calculo) {
      const calculoId = calculo.id;
      this.loadingLogsCalculo[calculoId] = true;
      ProgGeoPerdasAneelService.baixarLogsCalculo(calculoId)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `logs-calculo-prog-geo-perdas-aneel-${calculoId}-ctmt-${calculo.ctmt}.log`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error(
            'Falha no download dos logs do cálculo de perdas técnicas.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loadingLogsCalculo[calculoId] = false));
    }
  }
};
</script>

<style scoped>
.texto-aguardando {
  font-size: 90px;
  color: #3fa6f4;
}
.texto-executando {
  font-size: 90px;
  color: #f9a825;
}
.texto-falha {
  font-size: 90px;
  color: red;
}
.texto-sucesso {
  font-size: 90px;
  color: green;
}
.texto-pequeno {
  font-size: 9px;
}
</style>
